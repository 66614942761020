/*
 * ESS color definitions
 */
$ess-gray: rgb(237, 232, 220);
$ess-green-lighter: rgb(193, 207, 161);
$ess-green-light: rgb(165, 182, 141);
$ess-green-medium: rgb(83, 153, 14);
$ess-green-dark: rgb(56,101,14);
$ess-sand: rgb(237, 232, 220);
$ess-rose: rgb(231, 204, 204);
$ess-rose-dark: rgb(207,152,152);
/*
 * setting bootstrap colors
 */
$primary: $ess-green-light;
$link-color: $ess-green-dark;
$headings-color: $ess-green-dark;
$light: $ess-sand;
$dark: $ess-green-dark;
$warning: $ess-rose-dark;
$secondary: $ess-sand;
$danger: $ess-rose-dark;
$info: $ess-rose-dark;
$sucess: $ess-rose-dark;


/*
 * copying $spacer and $navbar-padding-y
 * for setting footer max-height
 */
$spacer: 1rem !default;
$navbar-padding-y: $spacer / 2 !default;
$footer-height: 4rem;

footer {
  max-height: $footer-height;
  img {
    max-height: $footer-height - 2 * $navbar-padding-y;
  }
}

/*
 * add margin-bottom according to
 * the sticky footer height
 */
#main-content-container {
  margin-bottom: $footer-height;
}

/*
 * old styles in use
 */
.legacy_alert {
  color:red !important;
}

.strike {
  text-decoration:line-through;
}

.byline {
  font-style: italic;
  text-align: right;
}

.slots, .teachers {
  list-style: none;
}

.gallery {
    display: flex;
    flex-wrap: wrap; /* Ermöglicht das Umbruch der Bilder */
    justify-content: space-between; /* Gleiche Abstände zwischen Bildern */
}

.gallery img {
    max-width: 250px; /* Bild passt in den Container */
    height: auto; /* Proportionaler Höhenverlauf */
    margin-bottom: 10px; /* Abstand zwischen den Bildern */
    flex: 1 1 200px; /* Flexibles Wachstum, Mindestbreite von 200px */
}

